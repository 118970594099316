<template>
  <div class="detial">
    <div class="backColor">
      <div class="top w1120">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ name: 'learn', query: { isHome: true } }"
            >经典案例</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/learn/home' }">
            {{ labelDetial.labelName }}
          </el-breadcrumb-item>
          <el-breadcrumb-item>{{ caseDetial.caseName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <div class="content w1120">
      <div class="video-content">
        <video controls :src="caseDetial.caseMp4" autoplay loop></video>
      </div>
      <h3>{{ caseDetial.caseName }}</h3>
      <p>发布时间：2022-01-01</p>
    </div>
  </div>
</template>
<script>
import { getOneCase } from "../../../../api";

export default {
  async created() {
    let res = await getOneCase(this.$route.query.caseId);
    ({ acase: this.caseDetial, label: this.labelDetial } = res.data.data);
  },
  data() {
    return {
      caseDetial: {},
      labelDetial: {},
    };
  },
};
</script>
<style lang="scss" scoped>
.detial {
  margin-top: 60px;
  .top {
    height: 40px;
    display: flex;
    align-items: center;
  }
  .content {
    height: 866px;
    padding-top: 67px;
    .video-content {
      width: 1120px;
      height: 630px;
      video {
        object-fit: fill;
        width: 100%;
        height: 100%;
      }
    }
    h3 {
      font-weight: 400;
      font-size: 32px;
      color: #333439;
      margin-top: 25px;
    }
    p {
      font-weight: 400;
      font-size: 13px;
      color: #333439;
      margin-top: 16px;
    }
  }
}
</style>
